<template>
  <div ref="slider" class="slider" :class="[containerClass]">
    <div class="slider__wrapper swiper-wrapper" :class="[wrapperClass]">
      <slot />
    </div>
    <div
      v-if="$slots && $slots.default && $slots.default.length && showControls"
      class="slider__nav"
    >
      <div
        class="slider__nav-inner"
        ref="slider-nav"
        :class="{ 'slider__nav-inner--white-arrows': whiteArrows }"
      >
        <div
          ref="slideprev"
          class="slide-arrow slide-prev icon-arrow-left"
        ></div>
        <div
          ref="slidenext"
          class="slide-arrow slide-next icon-arrow-right"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Swiper from "swiper/bundle";

const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  containerClass: {
    default: "",
    type: String,
  },
  wrapperClass: {
    default: "",
    type: String,
  },
  speed: {
    default: 300,
    type: Number,
  },
  whiteArrows: {
    type: Boolean,
    default: false,
  },
  loop: {
    type: Boolean,
    default: false,
  },
  freeMode: {
    type: Boolean,
    default: false,
  },
  slideToClickedSlide: {
    type: Boolean,
    default: false,
  },
  simulateTouch: {
    type: Boolean,
    default: false,
  },
  showControls: {
    type: Boolean,
    default: true,
  },
  autoPlay: {
    type: Number,
    default: null,
  },
  spaceBetween: {
    type: Number,
    default: 0,
  },
  slidesPerView: {
    type: [String, Number],
    default: "auto",
  },
});

const swiperClass = ref(null);
const slider = ref(null);
const slidenext = ref(null);
const slideprev = ref(null);

onMounted(() => {
  swiperClass.value = new Swiper(slider.value, {
    loop: props.loop,
    slidesPerView: props.slidesPerView,
    simulateTouch: props.simulateTouch,
    autoHeight: false,
    slideToClickedSlide: props.slideToClickedSlide,
    speed: props.speed,
    spaceBetween: props.spaceBetween,
    autoplay: props.autoPlay
      ? {
          delay: props.autoPlay,
        }
      : false,
    // cssMode: true,
    navigation: {
      nextEl: slidenext.value,
      prevEl: slideprev.value,
    },
    mousewheel: {
      forceToAxis: true,
    },
    breakpoints: {
      768: {
        freeMode: props.freeMode,
      },
    },
  });
});
</script>

<style lang="scss">
// @import "~swiper/swiper.scss";

.slider__nav {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.slider__nav-inner {
  margin-left: -$spacing-xs;
  margin-right: -$spacing-xs;
  display: flex;
  align-items: center;

  &--white-arrows {
    color: $color-white;
  }
}

.slide-arrow {
  font-size: $text-xl;
  padding: $spacing-xs;
  // margin-left: $spacing-xxs;

  &.swiper-button-disabled {
    opacity: 0.2;
  }
}
</style>
